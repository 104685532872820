import usericon from '../icons/profile.png'
import { Link } from 'react-router-dom'
import { useContext } from 'react'
// import Context,{UserControl} from './userAuth'
import AuthContext from './userAuth';

const Mainprofilepart = () => {
    const { user } = useContext(AuthContext);

    let a = document.querySelectorAll('.links a')
    a.forEach(elem => {
        elem.addEventListener('click', (e) => {
            a.forEach(em => em.classList.remove('active'))
            e.target.classList.add('active')
        })
    })

    return (
        <>
            <section id="profile">

                <div className="information">
                    <div className="pic">
                        <img src={usericon} alt="" />
                    </div>
                    {
                        user ? (
                            <div className="info">
                                <div className="user">
                                    <h2> {user.username=="null"?(<Link to='/services/gradecard'>reload for name</Link>):user.username}</h2>
                                    <div className="row flex" style={{ flexDirection: 'row', }}>
                                        <span className='enrandprg'>

                                            <p>Enrollment </p>
                                            <p>Program </p>
                                        </span>
                                        <span>

                                            <p>:  {user.enrollment}</p>
                                            <p>:  {user.program}</p>
                                        </span>
                                    </div>
                                    <button className='input' style={{ width: 'max-content', minWidth: 'max-content' }} onClick={() => { localStorage.setItem('uuid', ''); window.location.reload() }}>Logout</button>
                                </div>
                            </div>
                        ) : (
                            <div className="userLogin info">
                                <span className="greeting">
                                    Hey User please login or join first !
                                </span>
                                <div style={{ flexDirection: 'row' }}>
                                    <Link to='/login' className='btn'>
                                        Login
                                    </Link>
                                    <Link to='/signup' className='btn'>
                                        Signup
                                    </Link>


                                </div>
                            </div>
                        )
                    }
                </div>

                <div className="links">
                    <Link to='/profile' className='active'>Academic</Link>

                    <Link to='/profile/personalinfo'>Personal Information</Link>
                </div>
            </section>

        </>
    )

}

const Profile = () => {


    return (
        <>
            <Mainprofilepart />
            <section className="academic">
                <div>
                    <div className="row flex" style={{ alignItems: 'center' }}>
                        <p><b>📄</b> Your Result</p> : <Link to="/services/gradecard">CHECK</Link>
                    </div>
                    <div className="row flex" style={{ alignItems: 'center' }}>
                        <p><b>🧾</b> Assignment Status</p> :<Link to="/services/assignmentstatus">CHECK</Link>
                    </div>
                </div>
            </section>
        </>
    )
}


export const PersonalInfo = () => {
    const { user } = useContext(AuthContext)
    return (
        <>
            <Mainprofilepart />
            <section className="academic">
                {
                    user &&


                    (<>
                        <div className="row flex">
                            <b>👨‍🎓</b><p> name</p> : <i>{user.username}</i>
                        </div>
                        <div className="row flex">

                            <b>📧</b><p>Enrollment No.</p> : <i >{user.enrollment}</i>
                        </div>

                        <div className="row flex">
                           <b>📚</b> <p>Program</p> : <i>{user.program}</i>
                        </div>
                    </>
                    )
                }

            </section >


        </>
    )
}




export default Profile;
