import logo from "./logo.svg";
import "./App.css";
import { useEffect, useState } from "react";
import { Routes, Route, redirect, json } from "react-router-dom";
import "./assets/style/main.css";
import "./assets/style/style.css";
import "./assets/style/mobile-500.css";
import "./assets/style/animation.css";
import Bottommenu from "./assets/componants/bottommenu";
import { programearr } from "./assets/componants/arrdata";
import Topmenu, { Search } from "./assets/componants/topmenu";
import More from "./assets/componants/more";
import Home from "./assets/componants/home";
import Assignment, { Checkurl } from "./assets/componants/assignment";
import Services from "./assets/componants/services";
import Gradecard from "./assets/componants/gradecard";
import Assignmentstatus from "./assets/componants/assignmentstatus";
import pageisunderconstruction from "./assets/componants/underconstruction";
import { DownloadAssignment } from "./assets/componants/downloadassignment";
import Pyp from "./assets/componants/pyp";
import notification, { Showdetails } from "./assets/componants/notification";
import axios from "axios";
import Profile, { PersonalInfo } from "./assets/componants/profile";
import Solveassignment from "./assets/componants/selectassignment";
import Book, { Viewbooks } from "./assets/componants/books";
import BecomePartnerPage from "./assets/componants/partner";
import ReferEarnPage from "./assets/componants/referearn";
import SettingsPage from "./assets/componants/setting";
import Tools, { Pdftotext } from "./assets/componants/tools";
import AuthContext from "./assets/componants/userAuth";
import Login from "./assets/componants/login";
import Signup from "./assets/componants/signup";
import searchpapers from "./assets/componants/searchpapers";
import Allnotes, { Notes } from "./assets/componants/notes";
import { Serchresult } from "./assets/componants/search";

function App() {
  const [userlogged, setuserlogged] = useState(false);
  const [logging, setLogging] = useState(false);
  const [user, setuser] = useState(null);
  const [type, setType] = useState(null);
  const [message, setMessage] = useState(null);
  const [bg, setbg] = useState("red");
  const [messagebox, setMessagebox] = useState(100);
  const url = "https://ignoutech.pythonanywhere.com/assets/login";

  useEffect(() => {
    if (localStorage.getItem("uuid") != null) {
      let uuid = localStorage.getItem("uuid");
      let form = new FormData();
      form.append("uuid", uuid);
      form.append("type", "getdata");
      axios.post(url, form).then((dt) => {
        let d = dt.data;
        if (d.status) {
          console.log(d)
          let a = JSON.parse(d.user)[0]["fields"];

          setuser(a);
          programearr.forEach((elem, ind) => {
            elem.forEach((el) => {
              if (a.program.toLowerCase() == el.toLowerCase()) {
                setType(ind + 1);
              }
            });
          });
        }
      });
    }
  }, []);

  const login = (data) => {
    setLogging(true);
    let form = new FormData();
    form.append("enrollment", data.enroll);
    form.append("password", data.pwd);
    form.append("type", "login");
    axios.post(url, form).then((dt) => {
      let d = dt.data;
      console.log(d.user);
      setMessage(d.message);
      setMessagebox(0);
      if (d.status) {
        setbg("green");
        setuser(data);
        setuserlogged(true);
        let a = JSON.parse(d.user)[0]["fields"].uuid;
        localStorage.setItem("uuid", a);

        window.location = "/";
      } else {
        setbg("red");
        setLogging(false);
      }
      setTimeout(() => {
        setMessagebox(100);
      }, 3000);
    });
  };

  const signup = (data) => {
    let form = new FormData();
    form.append("enrollment", data.enroll);
    form.append("program", data.prg);
    form.append("password", data.pwd);
    form.append("type", "signup");
    axios.post(url, form).then((dt) => {
      let d = dt.data;
      setMessage(d.message);
      setMessagebox(0);
      if (d.status) {
        setuser(data);
        setbg("green");
        setuserlogged(true);
        let a = JSON.parse(d.user)[0]["fields"].uuid;
        localStorage.setItem("uuid", a);
        window.location = "/";
      } else {
        setbg("red");
      }
      setTimeout(() => {
        setMessagebox(100);
      }, 3000);
    });
  };
  const logout = () => {
    setuser(null);
  };

  const alert = (msg, color) => {
    setMessage(msg);
    setbg(color);
    setMessagebox(0);
    setTimeout(() => {
      setMessagebox(100);
    }, 3000);
  };

  useEffect(() => {
    // Make API call to track visit
    if (localStorage.getItem("oldvisiter") == null) {
      axios.get("https://ignoutech.pythonanywhere.com/assets/totalvisit");
      localStorage.setItem("oldvisiter", "");
    }
  }, []);

  const adderror = async (related, error) => {
    const url = "https://ignoutech.pythonanywhere.com/assets/error";
    const payload = {
      related: related,
      error: error,
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
      } else {
        const errorData = await response.json();
      }
    } catch (error) {
    }
  };

  const handleClick = (e) => {
    if (document.body.classList.contains("toggle")) {
      document.body.classList.remove("toggle");
    }
  };
  const val = {
    userlogged,
    user,
    login,
    signup,
    logout,
    alert,
    type,
    logging,
    adderror,
  };
  return (
    <>
      <AuthContext.Provider value={val}>
        <Topmenu />
        <section className="homepartition">
          <div className="pagemenubar tg">
            <More />
          </div>
          <div className="pagecontent" onClick={handleClick}>
            <Routes>
              <Route path="/" Component={Home}></Route>
              <Route path="/more" Component={More}></Route>
              <Route path="/assignment" Component={Assignment}></Route>
              <Route
                path="/assignment/select"
                Component={Solveassignment}
              ></Route>
              <Route path="/notification" Component={notification}></Route>
              <Route path="/notification/:heading" Component={Showdetails}></Route>
              <Route path="/assignment/:id" Component={Checkurl}></Route>
              <Route
                path="/assignment/:id/:id"
                Component={DownloadAssignment}
              ></Route>
              <Route path="/services" Component={Services}></Route>
              <Route
                path="/profile"
                Component={Profile}
                logged={userlogged}
              ></Route>
              <Route
                path="/profile/personalinfo"
                Component={PersonalInfo}
              ></Route>
              <Route path="/services/gradecard" Component={Gradecard}></Route>
              <Route path="/previousyearpaper" Component={Pyp}></Route>
              <Route
                path="/previousyearpaper/:id"
                Component={searchpapers}
              ></Route>
              <Route
                path="/services/assignmentstatus"
                Component={Assignmentstatus}
              ></Route>
              <Route path="/books" Component={Book}></Route>
              <Route path="/books/:program" Component={Viewbooks}></Route>
              <Route path="/notes" Component={Allnotes}></Route>
              <Route path="/notes/:program" Component={Notes}></Route>
              <Route
                path="/becomeapartner"
                Component={BecomePartnerPage}
              ></Route>
              <Route path="/referandearn" Component={ReferEarnPage}></Route>
              <Route path="/setting" Component={SettingsPage}></Route>
              <Route path="/tools" Component={Tools}></Route>
              <Route path="/tools/pdftotext" Component={Pdftotext}></Route>
              <Route path="/login" Component={Login}></Route>
              <Route path="/signup" Component={Signup}></Route>
              <Route path="/videos" Component={pageisunderconstruction}></Route>
              <Route path="/search/:query" Component={Serchresult}></Route>
              
              <Route component={pageisunderconstruction} />
            </Routes>
            {/* <Bottommenu /> */}
          </div>
        </section>
        <Routes>
          <Route path="/search" Component={Search}></Route>
        </Routes>
        <span
          className="showmessage"
          style={{ background: bg, transform: `translateX(${messagebox}%)` }}
        >
          {message}
        </span>
      </AuthContext.Provider>
    </>
  );
}

export default App;
