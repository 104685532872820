import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Link, redirect } from "react-router-dom";
import AuthContext from "./userAuth";
import logo from '../icons/logo2.png'
export default function Login() {
    const {login,user,logging,userlogged,alert}=useContext(AuthContext)

    const [enrollment,setEnrollment]=useState('')
    const [password,setPassword]=useState('')
    const [loader,setLoader]=useState(false)
    useEffect(()=>{
      if(user) window.location='/'
    },[])

    const data={
        enroll:enrollment,
        pwd:password
    }
    const handleClick=()=>{
      if(enrollment.length>9 && password.length>=8){

        login(data)
      setLoader(true)
      }
      alert('password length will >=8 and enrollmen no is required',"red")

    }
  return (
    <>
    {userlogged && <h1 >Hii</h1>}
    {
        !user &&
        <>
        <img src={logo} alt="" className="logoimg" />
        <section id="loginpage " className="alignjustifycenter flex-column">
        <div className="login">
          <h3 className="text-white border-bottom">Login</h3>
          <div className="logindetails flex-column">
            <label htmlFor="enroll">
              <input type="text" className="input" onChange={(e)=>setEnrollment(e.target.value)} name="enroll" id="" placeholder="Enrollment No." required/>
            </label>
            <label htmlFor="enroll">
              <input type="password" name="enroll" className="input" onChange={(e)=>setPassword(e.target.value)}  id="" placeholder="password" required/>
            </label>
            <label htmlFor="btn">
              <input type="button" onClick={handleClick} className={!logging?'button input':'miniloader '} value='Login' name="enroll" id="" onChange={(e)=>setPassword(e.target.value)}  placeholder="password" />
            </label>
          </div>
          <span className="space-between">
            <Link to='/forgotpasword'>forgot Password</Link>
            <Link to='/signup'>Signup</Link>
          </span>
        </div>
      </section>
      </>
}
    </>
  );
}
