import constructionimg from '../images/construction.gif'

const pageisunderconstruction=()=>{
    return (
        <>
        <div style={{display:'flex',justifyContent:'center',background:'#ebebeb'}}>
        <img src={constructionimg} alt="under construction" style={{mixBlendMode:"difference",maxWidth:"100vw"}} />
        </div>
        </>
    )
}

export default pageisunderconstruction;