import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import loading from '../images/loading.gif'
import { Link } from "react-router-dom";

const SearchPapers = () => {
    const { id } = useParams();
    const [papers, setPapers] = useState(null);
    const [data, setData] = useState(null);
    const [ar, setar] = useState([]);
    
    const url = 'https://ignoutech.pythonanywhere.com/assets/previouspaper';
    var arr;

    useEffect(() => {
        arr = id.split('-');
        ar[0]=arr[0]
        ar[1]=arr[1]
        if (!arr[0].startsWith('?')) {
            const requestData = {
                type: 'view',
                program: arr[0].toLowerCase(),
                coursecode: arr[1].toLowerCase(),
                year: arr[2] || null // Handling the case where 'year' might not be present
            };

            axios.post(url, requestData)
                .then(response => {
                    setPapers(response.data.papers);
                    console.log(response)
                    setData(response.data.data)
                })
                .catch(error => {
                    console.error("Error fetching papers:", error);
                });
        }
    }, [id]); // Adding 'id' as a dependency to re-run effect when 'id' changes


    const bytetomb = (bytes) => {
        return (bytes / (1024 * 1024)).toFixed(2)
    }

    return (
        <>
            <h1 style={{color:'white'}}>Question Papers of {ar[0]} ,{ar[1]}</h1>


            <>
                <div className="solveassignmentsfiles">

                    {

                        data && data.map((elem, ind) => (
                            (

                                <>
                                    <div className="book" style={{background:'transparent', boxShadow:'4px 3px 12px .2px black',}}>
                                        <span><img className='bookimg ' id={elem['uuid']} src={"data:image/png;base64," + elem.file[0]} alt={elem['course code']} /></span>
                                        <span>
                                            <h3>
                                                {elem['course code']}
                                            </h3>
                                            <div className="row">

                                            <h4><i>Code :</i> &nbsp; <b>{ar[1]}</b> </h4>
                                            <p className="margin-0"><i>Year :</i> &nbsp; <b>{elem.year} </b> </p>
                                            </div>
                                            <p className="margin-0"><i>Size :</i> &nbsp; <b>{bytetomb(elem.size)} MB</b> </p>
                                            <Link to={elem.uuid} >Download</Link>
                                        </span>
                                    </div>
                                </>
                            )
                        ))
                    }

                </div>
            </>


        </>
    );
};

export default SearchPapers;
