import { Link, redirect } from "react-router-dom";
import AuthContext from "./userAuth";
import { useState,useContext, useRef, useEffect } from "react";
import logo from '../icons/logo2.png'

export default function Signup() {

    const {signup,user}=useContext(AuthContext)

    const [enrollment,setEnrollment]=useState('')
    const [program,setProgram]=useState('')
    const [password,setPassword]=useState('')
    const [loader,setLoader]=useState(false)
    useEffect(()=>{
      if(user) window.location='/'
    },[])

    const data={
        enroll:enrollment,
        pwd:password,
        prg:program,
    }
    const handleClick=async()=>{
        // setLoader(true)
        signup(data)

     }
  return (

    <>
        <img src={logo} alt="" className="logoimg" />

      <section id="loginpage " className="alignjustifycenter">
        <div className="login">
          <h3 className="text-white border-bottom">Register</h3>
          <div className="logindetails flex-column">
            <label htmlFor="enroll">
              <input type="text" className="input " onChange={(e)=>setEnrollment(e.target.value)}  name="enrollment" id="" placeholder="Enrollment No." />
            </label>
            <label htmlFor="program">
              <input type="text" className="input" onChange={(e)=>setProgram(e.target.value)} name="program" id="" placeholder="Program." />
            </label>
            <label htmlFor="password">
              <input type="password" name="enroll" onChange={(e)=>setPassword(e.target.value)} className="input" id="" placeholder="password" />
            </label>
            <label htmlFor="enroll">
                {
                    loader?(

                        <span className="loader"></span>
                    ):(
                        <input type="button" className='button input' value='Signup ' onClick={handleClick} name="enroll"  id="" placeholder="password" />
                    )
                }
            </label>
          </div>
          <span className="space-between">
            <Link to='/'>Go Home</Link>
            <Link to='/login'>Login</Link>
          </span>
        </div>
      </section>
    </>
  );
}
