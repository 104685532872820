import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import file from '../icons/file.png'


const Notification = () => {
    const [notifications, setNotifications] = useState(null);

    useEffect(() => {
        axios.post('https://ignoutech.pythonanywhere.com/assets/notifications', { 'type': 'list' })
            .then(res => {
                setNotifications(res.data.notifications);
            })
            .catch(err => {
                console.error(err);
            });
    }, []);

    return (
        <>
            <section className="notificationpage">
                <h3>Notifications</h3>
                <div className="notifications">
                    {notifications ? (
                        notifications.map((elem, i) => (
                            <Link to={elem.title.replace(/\s/g,'_')}>
                                <div key={elem.uuid} className="notific">
                                    <p className="title">{elem.title}</p>
                                    <span className="datetime">{new Date(elem.datetime).getDate() + '/' + new Date(elem.datetime).getUTCMonth() + '  ' + new Date(elem.datetime).toLocaleTimeString()}</span>
                                </div>
                            </Link>
                        ))
                    ) : (
                        <span className="alignjustifycenter">

                            <div className="smallloader"></div>
                        </span>
                    )}
                </div>
            </section>
        </>
    );
};


export const Showdetails = () => {
    const { heading } = useParams()
    const [data, setData] = useState(null)
    useEffect(() => {
        axios.post('https://ignoutech.pythonanywhere.com/assets/notifications', { 'type': 'getinfo', 'uuid': heading.replace(/_/g,' ') })
            .then(res => {
                setData(res.data.notifications);
                console.log(res.data)
            })
            .catch(err => {
                console.error(err);
            });

    }, [])
    const handleclick = async (e) => {
        try {
            let res = await axios.post('https://ignoutech.pythonanywhere.com/assets/notifications', { 'type': 'download', 'uuid': e });
            let blob = new Blob([res.data], { type: 'application/pdf' });
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = 'file.pdf';  // You may want to customize this filename
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    };

    return (
        <>
            {data ? (
                <div className="fullnotification">
                    <h1 className="">{data.title}</h1>
                    <p className="">
                        {data.para}
                    </p>
                    {data.file && (
                        <div className="input flex alignjustifycenter" >
                            <img src={file} alt="" style={{ height: '50px' }} className="file" />
                            <Link to={'https://ignoutech.pythonanywhere.com/assets/downloadfile/'+data.uuid} className="btn" style={{ padding: '10px' }} >Download</Link>
                        </div>)
                    }
                </div>) : (
                <>
                    <div className="alignjustifycenter">
                        <div className="smallloader"></div>
                    </div>
                </>
            )
            }
        </>
    )
}


export default Notification;
