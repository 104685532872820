import { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import file from '../icons/duplicate.png'  // Assuming you have an image named `bcs_012.jpg`
import { bookimages } from './arrdata.js'
import { ClipLoader } from 'react-spinners'
import { Link, useParams } from 'react-router-dom'
import AuthContext from './userAuth.jsx'
export default function Allnotes() {
    const [bookCounts, setBookCounts] = useState(null)
    const [searchprg, setsearchprg] = useState(null)

    useEffect(() => {
        axios.post('https://ignoutech.pythonanywhere.com/assets/notes', { 'type': 'allnotes' })
            .then(res => {
                setBookCounts(res.data)
            })
            .catch(error => {
                console.error("There was an error fetching the book counts!", error)
            });


    }, [])
    const bookimg = (arr) => {
        let a = Math.floor(Math.random() * bookimages.length)
        return arr[a]
    }

    return (
        <section id="bookpage">
            <span className="bookheading maxwidth notessearch">
                <h2>📝 Notes..</h2><div className="searchfornote maxwidth"><input type="text" placeholder='program/course-code' onChange={(e)=>setsearchprg(e.target.value)}/><Link to={searchprg}><button>Serach</button></Link></div>
            </span>
            <div className="books" style={{ display: 'flex' }}>
                {bookCounts ? (Object.entries(bookCounts).map(([program, count], i) => (
                    <div className="book" key={i}>
                        <span className="img">
                            {/* <img src={file} alt={program} style={{ borderRadius: '50%' }} /> */}
                            <p data-content={program.toUpperCase()} style={{transform:'translateX(0px)'}}>📑</p>
                        </span>
                        <span className="info">
                            <h4 style={{padding:"3px",borderBottom:'1px solid white'}}>
                                {program.toUpperCase()} Notes
                            </h4>
                            <span>Available Notes: {count}</span>

                            <Link to={program.toLowerCase()}>View All</Link>
                        </span>
                    </div>
                ))) :
                    (
                        <div className="">
                            <ClipLoader size={50} color={"#123abc"} loading={true} />
                        </div>

                    )
                }
            </div>
        </section>
    )
}


export const Notes = () => {
    const { program } = useParams()
    const [from, setFrom] = useState(0)
    const [books, setBooks] = useState(null)
    const {adderror} = useContext(AuthContext)
    const [server,setserver]=useState(false)

    useEffect(
        () => {
            axios.post('https://ignoutech.pythonanywhere.com/assets/notes', { 'type': 'notes', 'program': program.toLowerCase(), "from": from })
                .then(res => {
                    setBooks(res.data.data)
                    if(res.data.data.length<1){
                        adderror('notes',program)
                        setserver(true)

                    }
                })
                .catch(error => {
                    console.error("There was an error fetching the book counts!", error)
                });


        }, [from])

    return (
        <>
            <section id="bookpage">
                <span className="bookheading maxwidth">
                    <h2 >📝 Notes..</h2> 
                </span>
                <h3 style={{textAlign:'center',color:'white',padding:"10px"}}>{program} Notes</h3>
                <div className="books" style={{ display: 'flex' }}>
                    {books ? (books.map((elem, i) => (
                        <div className="book" key={i}>
                            <span className="img">
                                <img src={file} alt='' style={{filter:'invert(1)'}}/>
                                <p data-content={elem.coursecode.toUpperCase()} style={{position:'absolute'}}></p>
                            </span>
                            <span className="info">
                                <h4 style={{padding:"3px",borderBottom:'1px solid white'}}>
                                    {elem.coursecode.toUpperCase()}
                                </h4>
                                <span>size: {(elem.size / (1024 * 1024)).toFixed(2)} MB.</span>
                                {/* <p>
                                    <b>Rates:</b> ⭐⭐⭐⭐ <p>⭐</p>
                                </p> */}
                                <a  href={'https://ignoutech.pythonanywhere.com/assets/notes/' + elem.uuid} >Download</a>
                            </span>
                            
                        </div>
                    ))) :
                        (
                            <div className="">
                                <ClipLoader size={50} color={"#123abc"} loading={true} />
                            </div>

                        )
                    }
                </div>
                {server && (<>
                            <div className="servererror">
                                <h1>⚠</h1>
                                <p>Server error please come after 30-40 minutes</p>
                            </div>
                            
                            </>)}
            </section>
        </>
    )
}



export const Downloadnote=()=>{
    return (
        <>



        
        </>
    )
}