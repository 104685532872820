import logo from "../icons/logo2.png";
import bell from "../icons/bell.png";
import messageicon from "../icons/message.png";
import menubar from "../icons/menu.png";
import profile from "../icons/profile.png";
import { Searchbar } from "./home";

import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

function Topmenu() {
  const [toggle, setToggle] = useState(false);
  const [submenu, setSubmenu] = useState(false);
  const [submenudetail, setSubmenudetail] = useState(false);
  const handleClick = () => {
    if (document.body.classList.contains("toggle")) {
      document.body.classList.remove("toggle");
    } else {
      document.body.classList.add("toggle");
    }
  };
  useEffect(() => {
    let a = document.getElementById("user");
    a.onmouseover = (e) => {
      if (!submenu) {
        setSubmenu(true);
        let elem = document.querySelector(".accountmenu ul");
        elem.classList.add("show");
        elem.onmouseover = () => {
          setSubmenu(true);
          setSubmenudetail(true);
        };

        elem.onmouseleave=() => {
         
            elem.classList.remove("show");
            setSubmenudetail(false)
          
        }

        elem.onclick=() => {
         
            elem.classList.remove("show");
            setSubmenudetail(false)
          
        }
      }
    };
    a.onmouseleave = () => {
        setTimeout(()=>{

            if (submenu && !submenudetail) {
              setSubmenu(false);
         document.querySelector(".accountmenu ul").classList.remove('show');

            }
        },1001)
    };
    window.onclick = () => {
       
         document.querySelector(".accountmenu ul").classList.remove('show');

    };
  }, []);
  return (
    <>
      <section id="topmenu">
        <div className="topmenu">
          <div className="menubar" onClick={handleClick}>
            <img src={menubar} alt="menubar" />
          </div>
          <div className="mainlogo">
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </div>
        </div>
        <Searchbar />
        <div className="notification">
          <Link to="/message">
            <img src={messageicon} alt="" style={{ transform: "scale(1.1)" }} />
          </Link>
          <Link to="/notification">
            <img src={bell} alt="" />
          </Link>
          <Link to="/profile">
            <img src={profile} alt="" id="user" />
          </Link>
        </div>
      </section>
      <section className="submenu">
        <div className="accountmenu">
          <ul>
            <li>🙎‍♂️ Account</li>
            <li>📧 Messages</li>
            <Link to='profile#shows'>
            <li>📺 Show</li>
            </Link>
          </ul>
        </div>
      </section>
    </>
  );
}

export function Search() {
  const [query,setQuery]=useState('')
  return (
    <>
      <div className="seracharea">
        <div id="search">
          <input
            type="search"
            name="search"
            id="searchinput "
            placeholder="Search for Ignou assignments,Notes, ignou.."
            onChange={(e)=>setQuery(e.target.value)}
          />
          <Link to={query.trim().replace(/\s/g,'_')}>Search</Link>
        </div>
        <div id="searchreasult"></div>
      </div>
    </>
  );
}

export default Topmenu;
