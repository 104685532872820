import { useEffect, useState } from 'react'
import axios from 'axios'
import file from '../images/bcs_012.jpg'  // Assuming you have an image named `bcs_012.jpg`
import { bookimages } from './arrdata.js'
import { ClipLoader } from 'react-spinners'
import { Link, useParams } from 'react-router-dom'

export default function Book() {
    const [bookCounts, setBookCounts] = useState(null)

    useEffect(() => {
        axios.post('https://ignoutech.pythonanywhere.com/assets/books', { 'type': 'allbooks' })
            .then(res => {
                setBookCounts(res.data)
            })
            .catch(error => {
                console.error("There was an error fetching the book counts!", error)
            });


    }, [])
    const bookimg = (arr) => {
        let a = Math.floor(Math.random() * bookimages.length)
        return arr[a]
    }

    return (
        <section id="bookpage">
            <span className="bookheading">
                <h2>📗 Books..</h2>
            </span>
            <div className="books" style={{ display: 'flex' }}>
                {bookCounts ? (Object.entries(bookCounts).map(([program, count], i) => (
                    <div className="book" key={i}>
                        <span className="img">
                            {/* <img src={file} alt={program} /> */}
                            <p data-content={program.toUpperCase()}>{bookimg(bookimages)}</p>
                        </span>
                        <span className="info">
                            <h4>
                                {program.toUpperCase()} BOOKS
                            </h4>
                            <span>Total Books: {count}</span>
                            <p>
                                <b>Rates:</b> ⭐⭐⭐⭐ <p>⭐</p>
                            </p>
                            <Link to={program.toLowerCase()}>view</Link>
                        </span>
                    </div>
                ))) :
                    (
                        <div className="">
                            <ClipLoader size={50} color={"#123abc"} loading={true} />
                        </div>

                    )
                }
            </div>
        </section>
    )
}


export const Viewbooks = () => {
    const { program } = useParams()
    const [from, setFrom] = useState(0)
    const [books, setBooks] = useState(null)

    useEffect(
        () => {
            axios.post('https://ignoutech.pythonanywhere.com/assets/books', { 'type': 'books', 'program': program, "from": from })
                .then(res => {
                    setBooks(res.data.data)
                    console.log(res)
                })
                .catch(error => {
                    console.error("There was an error fetching the book counts!", error)
                });


        }, [from])

    return (
        <>
            <section id="bookpage">
                <span className="bookheading">
                    <h2>📗 Books..</h2>
                </span>
                <div className="books" style={{ display: 'flex' }}>
                    {books ? (books.map((elem, i) => (
                        <div className="book" key={i}>
                            <span className="img">
                                <img src={file} alt='' />
                                <b 
                                style={{
                                    position:'absolute',right:'10px',top:'48%',color:'#312d2d',fontSize:'14px',fontFamily:'serif'
                                }}>{elem.coursecode.toUpperCase()}</b>
                            </span>
                            <span className="info">
                                <h4 style={{padding:"3px",borderBottom:'1px solid white'}}>
                                    {elem.coursecode.toUpperCase()}
                                </h4>
                                {/* <span>Total Books: {count}</span> */}
                                <p style={{padding:'3px 8px'}}>
                                    <b>{elem.link=='null' ? '📄 PDF' : '📚 HARD-COPY'}</b> 
                                </p>
                                <Link to={elem.link=='null' ? 'https://ignoutech.pythonanywhere.com/assets/books/'+elem.uuid : elem.link }  >{elem.link=='null' ? 'Download' : 'purchase'}</Link>
                            </span>
                        </div>
                    ))) :
                        (
                            <div className="">
                                <ClipLoader size={50} color={"#123abc"} loading={true} />
                            </div>

                        )
                    }
                </div>
            </section>
        </>
    )
}
