
import notes from '../icons/edit.png'
import ass from '../icons/ballot.png'
import update from '../icons/refresh.png'
import book from '../icons/book.png'
import partner from '../icons/handshake.png'
import rupee from '../icons/rupee.png'
import service from '../icons/services.png'
import support from '../icons/telephone.png'
import setting from '../icons/settings.png'
import toolsicon from '../icons/tools.png'
import home from '../icons/home.png'
import { useEffect } from 'react'

import { Link, link } from 'react-router-dom'
import { toContainHTML } from '@testing-library/jest-dom/matchers'


function More() {

    useEffect(() => {
        const liElements = document.querySelectorAll('li');

        liElements.forEach(li => {
            if (!li.onclick) {
                li.addEventListener('click', handleClick);
            }
        });

        return () => {
            liElements.forEach(li => {
                if (!li.onclick) {
                    li.removeEventListener('click', handleClick);
                }
            });
        };
    }, []);
    const handleClick = (event) => {
        // Logic to handle click event
        document.body.classList.remove('toggle')
    };

    const handleGoBack = () => {
        window.history.back(); // Navigate to the previous page
    };
    let a = document.querySelectorAll('#menulistul li')
    a.forEach(elem => {
        elem.addEventListener('click', (e) => {
            a.forEach(em => em.classList.remove('active'))
            elem.classList.add('active')
        })
    })
    return (
        <>
            <section id="more">
                <div className="menulist">
                    {/* <h3 className='goback' onClick={handleGoBack}>←</h3> */}
                    <ul id='menulistul'>
                        <Link to='/'>
                            <li className='active'>

                                <span>
                                    <img src={home} alt="" />
                                    <p>Home</p>
                                </span>
                            </li>
                        </Link>
                        <Link to='assignment'>
                            <li>

                                <span>
                                    <img src={ass} alt="" />
                                    <p>Solve Assignment</p>
                                </span>
                            </li>
                        </Link>
                        <Link to='notes'>
                            <li>

                                <span>
                                    <img src={notes} alt="" />
                                    <p>Notes</p>
                                </span>
                            </li>
                        </Link>
                        <Link to='books'>
                            <li>
                                <span>
                                    <img src={book} alt="" />
                                    <p>Books</p>
                                </span>
                            </li>
                        </Link>
                        <Link to='services'>
                            <li>
                                <span>
                                    <img src={service} alt="" />
                                    <p>Services</p>
                                </span>
                            </li>
                        </Link>
                        <Link to='/ignouupdates'>
                            <li>
                                <span>
                                    <img src={update} alt="" />
                                    <p>Ignou Updates</p>
                                </span>
                            </li>
                        </Link>
                        <Link to='/tools'>
                            <li>
                                <span>
                                    <img src={toolsicon} alt="" />
                                    <p>Tools</p>
                                </span>
                            </li>
                        </Link>
                        <Link to='becomeapartner'>
                            <li>
                                <span>
                                    <img src={partner} alt="" />
                                    <p>Become Partner</p>
                                </span>
                            </li>
                        </Link>
                        <Link to='referandearn'>
                            <li>
                                <span>
                                    <img src={rupee} alt="" />
                                    <p>Refer & Earn</p>
                                </span>
                            </li>
                        </Link>
                        <Link to='setting'>
                            <li>
                                <span>
                                    <img src={setting} alt="" />
                                    <p>Setting</p>
                                </span>
                            </li>
                        </Link>

                        <li>
                            <span>
                                <img src={support} alt="" />
                                <p>Support</p>
                            </span>
                        </li>
                    </ul>
                </div>
            </section>
        </>
    )
}

export default More;