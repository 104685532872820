import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AuthContext from "./userAuth";

const Search=()=>{
    return(
        <>
        <input type="search" name="" id="" placeholder="search here"/>
        </>
    )
}

export const Serchresult = () => {
    const { query } = useParams();
    const {adderror} = useContext(AuthContext)
    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const formattedQuery = query.replace(/_/g, ' ');
                const response = await axios.post('https://ignoutech.pythonanywhere.com/assets/search', { query: formattedQuery });
                setData(response.data.results);
                adderror('search',formattedQuery)
            } catch (error) {
                console.error('Error fetching search results:', error);
            }
        };

        fetchData();
    }, [query]);  // Include 'query' as a dependency to refetch when URL param changes

    return (
        <div style={{ margin: '20px', padding: '20px', border: '1px solid #ccc', borderRadius: '5px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
            <h2 style={{ fontSize: '24px', marginBottom: '15px' }}>Search Results for "{query}"</h2>
            {data ? (
                <div style={{ marginTop: '20px' }}>
                    {Object.keys(data).map((category, index) => (
                        <div key={index} style={{ marginBottom: '30px' }}>
                            <h3 style={{ fontSize: '20px', marginBottom: '10px' }}>{category.charAt(0).toUpperCase() + category.slice(1)}</h3>
                            <ul style={{ listStyle: 'none', padding: '0' }}>
                                {data[category].map((item, idx) => (
                                    <li key={idx} style={{ backgroundColor: '#fff', padding: '15px', border: '1px solid #e0e0e0', borderRadius: '5px', marginBottom: '15px' }}>
                                        <h4 style={{ fontSize: '18px', marginBottom: '5px' }}>{item.title}</h4>
                                        {item.description && <p style={{ color: '#555' }}>{item.description}</p>}
                                        {item.date && <p style={{ fontStyle: 'italic', color: '#777' }}>Date: {item.date}</p>}
                                        {/* Add more fields as needed */}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            ) : (
                <p style={{ fontStyle: 'italic', color: '#777' }}>Loading...</p>
            )}
        </div>
    );
};

export default Search;